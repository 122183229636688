// netzwerk page - netzwerk.js

import bg1 from "assets/bilder/internet_telefon/KV_AD_9578_rgb.jpg"
import bg2 from "assets/bilder/internet_telefon/KV_AD_9734_rgb.jpg"
import bg3 from "assets/bilder/internet_telefon/KV_IMG_6852_rgb.jpg"

import eins from "assets/bilder/internet_telefon/IMG_6833_rgb.jpg"
import zwei from "assets/bilder/internet_telefon/AD_9726_rgb.jpg"
import drei from "assets/bilder/internet_telefon/AD_9645_rgb.jpg"
import vier from "assets/bilder/internet_telefon/AD_9650_rgb.jpg"



export const netzwerkContent = {
    h2: "Internet / Telefon ",
    subtitle: "Bleiben Sie im Gespräch – optimal vernetzt.",
    bgs: [
        bg1,bg2,bg3
      ],    mainContent: `<p>
    

Ein leistungsfähiges Netzwerk gehört heute in den Haushalt wie früher der Telefonanschluss. In beiden Fällen ist Elektro Huwiler der zuverlässige Partner. Ob es um die Portierung einer Telefonnummer, ein Voice Over IP, ein umfangreiches Netzwerk für KMU’s oder einen einfachen Telefonanschluss handelt, hier sind sie richtig - wir kennen uns aus und helfen Ihnen gerne weiter.
<br></br>
Sowohl physische Anlagen wie auch Cloud-basierte Lösungen gehören zusammen mit unseren Partnern, der Swisscom und der iWay, zum täglichen Repertoire. Funktionell, sicher und mit Schweizer Genauigkeit und Qualität.
<br></br>

Aufgrund gängiger Anforderungen von SmartHome und diverser Streaming-Diensten setzen wir uns ein, dass die maximale Geschwindigkeit, die Ihre Infrastruktur liefern kann, installiert wird. Zusammen mit unseren Glasfaserspezialisten begleiten wir Sie gerne in eine flüssige Zukunft.
<br></br>

So bleiben Sie mit Ihren Liebsten in Kontakt.          
     <br></br> 
     Für weitere Informationen stehen wir Ihnen jederzeit über unser
     <a href="/kontakt?anliegen=internet">Kontaktformular</a> zur Verfügung,
     schreiben Sie uns ein <a href="mailto:info@elektro-huwiler.ch">E-Mail</a> oder wir nehmen Ihren Anruf gerne auf
      <a href="tel:0447776544">044 777 65 44</a> entgegen.
      
      </p>
    `,
    modalImages: [
        eins,
        zwei,
        drei,
        vier,
    ],
};