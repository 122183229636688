import React from "react";
import { Layout, Seo } from "components/common";
import { MainContent } from "components/MainContent";
import GridModalImages from "components/GridModalImages";

import { netzwerkContent } from "pagesData/netzwerk";
import { IntroRando } from "components/IntroRandom";

const Netzwerk = () => (
  <Layout>
    <Seo />
    <IntroRando {...netzwerkContent} />
    <MainContent content={netzwerkContent.mainContent} />
    <GridModalImages images={netzwerkContent.modalImages} />

  </Layout>
);

export default Netzwerk;
